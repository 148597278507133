.login {

    &__container {
        display: flex;
        justify-content: center;
        min-height: 100vh;
        width: 100%;


        &__image {
            display: none;
            width: 50%;
            background-color: #000000;

            @media (min-width: 1024px) {
                display: block;
            }
        }

        &__content {
            width: 50%;
            display: flex;
            padding-top: 3rem;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            margin-top: 6%;

            @media (max-width: 1280px) {
                width: 50%;

            }

            @media (max-width: 1024px) {
                flex: none;
                width: 100%;

            }

        }

    }

    &__logo {
        margin-bottom: 2.5rem;
    }

    &__info {
        text-align: center;
        margin-top: 16px;
        color: var(--color-afya-darkgray);

        @media (max-width: 1024px) {
            width: 100vw;
        }
    }

    &__welcome {

        h2 {
            font-size: 36px;
            font-weight: 600;
            line-height: 46.08px;
            letter-spacing: -0.32px;
            text-align: center;
        }

        p {
            text-align: center;
            margin-top: 16px;
        }

    }

    &__forgot {
        width: 23.438rem;

        h2 {
            font-style: normal;
            font-weight: 600;
        }

        p {
            margin-top: 1.5rem;
            font-size: 1rem;
            line-height: 1.5rem;
            font-weight: 400;
            line-height: 1.188;
        }

    }

    &__alert {
        width: 23.438rem;
        margin-bottom: 1.5rem;
    }

    &__formlogin {
        width: 23.438rem;
        margin-top: 20px;

        &__alert {
            width: 23.438rem;
            justify-content: center;
        }

        button {
            height: 2.5rem;
            font-size: 1rem;
            font-weight: 500;
        }

        input {
            border: 1px solid #0000003B;
            border-radius: 4px;
            width: 23.438rem;
            height: 2.5rem;
        }

        label {
            font-size: 16px;
            font-weight: 700;
            list-style: 20.48px;
            letter-spacing: -0.32px;


        }

        &__btn {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 2.5rem;
            width: 23.438rem;
        }

    }

    &__content {
        display: flex;
        padding-top: 3rem;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}